

import { call, put, takeLatest } from "redux-saga/effects";
import { AINewsConstants } from "../../../Constants/AINewsConstants";
import aiNewsApi from "../../../ServiceApi/Apis/AINewsApi";

const {
    ActionTypes
} = AINewsConstants;

function* getCompetitiveEdgeData({ symbol }) {
    try {
        const response = yield call(aiNewsApi.getCompetitiveEdgeRequest, symbol)

        if (response.data) {
            yield put({
                type: ActionTypes.GET_COMPETITIVE_EDGE_SUCCESS,
                data: response.data,
                message: ''
            });
        }
        else {
            // error cases will be handled better when the backend code implements it.
            yield put({
                type: ActionTypes.GET_COMPETITIVE_EDGE_FAILURE,
                data: '',
                message: 'Data is Not Available'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_COMPETITIVE_EDGE_FAILURE,
            data: '',
            message: 'Something went wrong while fetching the data. Please try again after some time.'
        });
        
        console.log(`%cError occures in AINewsSaga.js, getCompetitiveEdgeData ${error}`);
    }
}

export function* watchCompetitiveEdge() {
    yield takeLatest(ActionTypes.GET_COMPETITIVE_EDGE_REQUEST, getCompetitiveEdgeData)
}