import ProtoBuf from 'protobufjs';
import StringUtil from 'StringUtil';
import ConsoleActions from "Actions/ConsoleActions";
import ProdServerURL from "ProdServerURL";
import ServiceErrorValidation from "./ServiceErrorValidation";
import LocalizationStore from "../Stores/Localization/LocalizationStore.js";
//const Promise = window.Promise || require("bluebird");
class BaseServiceApi {

  constructor() {
    const serverName = process.env.SERVER_NAME1;
    const ssl = process.env.SERVER_SSL1;
    this.serverName = !StringUtil.isEmpty(serverName) ? serverName.trim() : "";
    this.serverSSL = !StringUtil.isEmpty(ssl) ? ssl.trim() : "https";
    this.builder = ProtoBuf.loadProtoFile("raydata.proto");
    this.rayData = this.builder.build("tomcatData");

    if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
      this.hostname = self.location.hostname;
    }
    else {
      this.hostname = window.location.hostname;
    }
  }

  getRequestURL(request, isAIService = false) {
    const subsytem = this.getSubSystemPath(request.getSubSystem());
    const app_name = "rayweb";
    let servername = this.getServerName(isAIService);
    const uri = request.getRequestUri();
    const parameterstring = request.getParameterString();

    if (parameterstring)
      return StringUtil.format(`{0}://{1}${isAIService ? "" : "/{2}"}/{3}/{4}?{5}`, [this.serverSSL, servername, app_name, subsytem, uri, parameterstring]);
    else
      return StringUtil.format(`{0}://{1}${isAIService ? "" : "/{2}"}/{3}/{4}`, [this.serverSSL, servername, app_name, subsytem, uri]);
  }

  getServerName(isAIService = false) {
    let serverName, ai_serverName;

    if (this.serverName !== "") {
      return this.serverName;
    }

    if (process.env.BRANCH_ENV) {
      let env = process.env.BRANCH_ENV;
      switch (env && env.toUpperCase()) {
        case "DEV":
          serverName = "tomcat.dev.panaray.com";
          ai_serverName = `ai-dev.panaray.com`;
          break;
        case "STAGE":
          serverName = "tomcat.stage.panaray.com";
          ai_serverName = `ai-dev.panaray.com`;
          break;
        case "PROD":
          serverName = `tomcat.${this.getProdServerURL()}`;
          ai_serverName = `ai-api.panaray.com`;
          break;
        default:
          break;
      }
    }

    if (isAIService)
      return ai_serverName == null ? ai_serverName = "ai-dev.panaray.com" : ai_serverName;
    else
    return serverName == null ? serverName = "tomcat.stage.panaray.com" : serverName;
  }

  getSubSystemPath(subsystemtype) {
    let type;
    switch (subsystemtype) {
      case "LIST":
        type = "l";
        break;
      case "GRAPH":
        type = "g";
        break;
      case "USER":
        type = "u";
        break;
      case "FUNDAMENTAL":
        type = "f";
        break;
      case "GROUPS":
        type = "grp";
        break;
      case "ALERTS":
        type = "a";
        break;
      case "NOTIFICATION":
        type = "n";
        break;
      case "SHARE":
        type = "shr";
        break;
      case "NEWS":
        type = "ns";
        break;
      case "LOCALIZATION":
        type = "lz";
        break;
      case "METRICDEFINITION":
        type = "md";
        break;
      case "HUB":
        type = "hub";
        break;
      case "SSO":
        type = "sso";
        break;
      default:
        break;
    }

    return type;
  }

  logoutserverCall(request) {
    return this.processlogoutServiceCall(request);
  }

  processlogoutServiceCall(request) {
    /* eslint-disable */
    const self = this;
    return new Promise(function (resolve, reject) {
      /* eslint-enable */
      //return new Promise((resolve, reject) => {
      const url = this.getRequestURL(request);
      const method = request.getMethod();
      const content = request.getContent();


      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      if (content == undefined) {
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
      }
      xhr.responseType = "arraybuffer";
      xhr.withCredentials = true;
      const messageName = request.getMessageName();
      const ResponseProto = this.rayData[messageName];
      const serverName = this.getServerName();
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          let response = ResponseProto.decode(xhr.response);
          if (self.validateResponse(response, serverName, url)) {
            response.status = this.status;
            resolve(response);
          }
          else {
            reject();
          }
          // resolve({
          //   status: this.status
          // });
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };

      if (content == null) {
        xhr.send();
      }
      else {
        xhr.send(content.toArrayBuffer());
      }
    }.bind(this));
  }

  validateResponse(response, serverName, serviceName) {
    const ErrorType = this.rayData["ErrorType"];
    if (response.ResponseHeader) {
      const responseHeaderValue = response.ResponseHeader;
      delete response.ResponseHeader;
      response["responseHeader"] = responseHeaderValue;
    }
    response.responseHeader.server = serverName;
    response.responseHeader.serviceUriName = serviceName;
    return ServiceErrorValidation.validateResponse(response, ErrorType);
  }

  logAPICalls(uriName, xhr) {
    const serviceCallList = ['getpricepaneldata.pb', 'getsummaryblockdata.pb', 'findgetblockses.pb', 'findgetblocks2.pb', 'factoreventlist.pb', 'getkpidata.pb', 'gettechind.pb', 'getexternaldata.pb', 'extcategories.pb', 'annotations.pb', 'GetComparisonDataForSymbols', 'globalcounts2.pb', 'benchmarkdata2.pb', 'quaddata.pb',
      'explorer.pb', 'esbasic.pb', 'FindInstrumentDB2', 'liqfilters.pb', 'GetLatestTradingDate', 'geography.pb', 'groupsmain.pb', 'liqfilters.pb']

    for (var i = 0; i < serviceCallList.length; i++) {
      if (serviceCallList[i] === uriName) {
        console.log("PRINT:-------CALL: ", uriName, "--------URL: ", xhr.responseURL, "----------- STATUS:", xhr.status)
      }
    }
  }

  processServiceCall(request) {
    const self = this;
    return new Promise((resolve, reject) => {
      //return new Promise((resolve, reject) => {
      const url = this.getRequestURL(request);
      const uriName = request.getRequestUri();
      const serverName = this.getServerName();
      const method = request.getMethod();
      const messageName = request.getMessageName();
      const content = request.getContent();
      const ResponseProto = this.rayData[messageName];

      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      if (content == undefined) {
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
      }
      xhr.responseType = "arraybuffer";
      xhr.withCredentials = true;

      xhr.onload = function () {
        self.logAPICalls(uriName, xhr);
        if (this.status >= 200 && this.status < 300) {
          try {
            if (messageName === "blob") {
              var content = xhr.response;
              resolve(content);
            }
            let response = ResponseProto.decode(xhr.response);
            if (self.validateResponse(response, serverName, uriName)) {
              // self.logAPICalls(uriName, xhr);    
              resolve(response);
            }
            else {
              reject(response);
            }
          } catch (e) {
            reject();
          }
        } else {
          try {
            if (this.status == 401) {
              let response = ResponseProto.decode(xhr.response);
              if (response.responseHeader) {
                ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_14", "Another computer or device has logged into Panaray. This session has been terminated."), true, LocalizationStore.getTranslatedData("duplicateSession", "Duplicate Session"), true);
              }
            }
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
          catch (e) {
            reject();
          }
        }
      };
      xhr.onerror = function () {
        self.logAPICalls(uriName, xhr);
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
        if (this.status == 0) {
          ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_01", "Network connection is not available."));
        }
        else if (this.status == 504) {
          ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_13", "The service has reached its max timeout of 3600 seconds."));
        }
        else {
          ConsoleActions.exceptionHandle(xhr.statusText);
        }
      };

      if (content == null) {
        xhr.send();
      }
      else if (messageName == 'ChangePasswordResponse' || uriName == 'externalfileuploader.pb') {
        xhr.send(content);
      }
      else {
        xhr.send(content.toArrayBuffer());
      }
    });
  }

  getProdServerURL() {
    let prodServerURL = ProdServerURL[this.hostname];
    return StringUtil.isEmpty(prodServerURL) ? "services.panaray.com" : prodServerURL;
  }

  processPrintServiceCall(request) {
    const url = this.getRequestURL(request);
    const method = request.getMethod();
    const messageName = request.getMessageName();
    const content = request.getContent();

    return new Promise((resolve, reject) => {

      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      xhr.withCredentials = true;
      // xhr.setRequestHeader('Pragma', 'no-cache');  --- print status polling - Fix for IE browser

      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          let response = JSON.parse(xhr.response);

          if (messageName === 'PrintStatusResponse') {
            console.log("Print Reply : ", response["response"]["status"]);

            response = JSON.parse(JSON.stringify(response["response"]["status"]));

            console.log("Response from print server: ", response);
          }

          resolve(response);

          if (response && response.ErrorList !== undefined) {
            ConsoleActions.exceptionHandle(response.ErrorList);
            ConsoleActions.exceptionHandlePrint(response.ErrorList);
          }
        }
        else {
          if (messageName === 'PrintStartResponse') {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
          else
            reject();
        }
      };

      xhr.onerror = function () {
        if (this.status == 0) {
          console.log("PRINT:-------CALL:", messageName, ": *** Network connection is not available ***");
        }
        else if (this.status == 504) {
          console.log("PRINT:-------CALL:", messageName, ": The service has reached its max timeout of 3600 seconds ***");
        }
        else {
          console.log("PRINT:-------CALL:", messageName, ":", xhr.statusText, " ***");
        }

        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }

      if (content) {
        xhr.send(content.toArrayBuffer());
      }
      else {
        xhr.send();
      }
    });
  }

  processAIServiceCall(request) {
    const self = this;
    return new Promise((resolve, reject) => {
      const url = this.getRequestURL(request, true);
      const uriName = request.getRequestUri();
      const method = request.getMethod();
      const messageName = "";
      const content = request.getContent();

      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      if (content == undefined) {
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
      }

      let cookies = document.cookie.split(';').map(cookie => cookie.trim());
      cookies = cookies.find(cookie => cookie.startsWith('AI_SESSION_ID'))?.split('=')[1];

      if (cookies) {
        xhr.setRequestHeader('Cookies', cookies);
      }

      xhr.onload = function () {
        self.logAPICalls(uriName, xhr);
        if (this.status >= 200 && this.status < 300) {
          try {
            let response = JSON.parse(xhr.response);
            if (response) {
              resolve(response);
            }
            else {
              reject(response);
            }
          } catch (e) {
            reject();
          }
        } else {
          try {
            if (this.status == 401) {
              // let response = ResponseProto.decode(xhr.response);
              let response = xhr.response;
              if (response.responseHeader) {
                ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_14", "Another computer or device has logged into Panaray. This session has been terminated."), true, LocalizationStore.getTranslatedData("duplicateSession", "Duplicate Session"), true);
              }
            }
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
          catch (e) {
            reject();
          }
        }
      };
      xhr.onerror = function () {
        self.logAPICalls(uriName, xhr);
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
        if (this.status == 0) {
          // ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_01", "Network connection is not available."));
        }
        else if (this.status == 504) {
          ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_13", "The service has reached its max timeout of 3600 seconds."));
        }
        else {
          ConsoleActions.exceptionHandle(xhr.statusText);
        }
      };

      if (content == null) {
        xhr.send();
      }
      else {
        xhr.send(content.toArrayBuffer());
      }
    });
  }

}

const baseServiceApi = new BaseServiceApi();
export default baseServiceApi;
