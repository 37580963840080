import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';

import ThemeHelper from "ThemeHelper";

import { ActionTypes } from "Constants/AppColorConstants";
import { PriceChartConstants, PriceChartConst } from "Constants/PriceChartConstants.js";

import AppColorStore from "Stores/AppColor/AppColorStore";


const ZigzagVisual = ({ isVisible, zigzagIndicatorData, lineSettings, updateContextHit }) => {
    const [themeChanged, setThemeChanged] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [hovered, setHovered] = useState(false);
    const chartRef = useRef(null);

    const colorInfo = lineSettings ? ThemeHelper.getThemedBrush(lineSettings.color) : ThemeHelper.getThemedBrush('dg-zigzagLine')

    const lineStyle = {
        position: "absolute",
        left: "0px",
        top: "0px",
        pointerEvents: "stroke",
        width: "100%",
        height: "100%",
        zIndex: 2,
        fill: "none",
        stroke: colorInfo,
        strokeWidth: lineSettings ? lineSettings.weight : 2
    };

    /* Re-rendering the component with dummy boolean flag upon theme change to apply the relevant theme colors */
    const AppColorStoreStateChanged = () => {
        const currentAction = AppColorStore.getAction();
        if (currentAction === ActionTypes.CUSTOM_THEME_CHANGED) {
            setThemeChanged(prev => !prev);
        }
    };

    useEffect(() => {
        AppColorStore.addChangeListener(AppColorStoreStateChanged);

        return () => {
            AppColorStore.removeChangeListener(AppColorStoreStateChanged);
        };
    }, []);


    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        const dimensions = chartRef.current.getBoundingClientRect();
        const chartHeight = dimensions.height;

        const tooltipX = offsetX;
        const tooltipY = offsetY > chartHeight - 40 ? offsetY - 20 : offsetY + 20;

        setTooltipPosition({ x: tooltipX, y: tooltipY });
        setHovered(true);
    };


    const prepareZigzag = (zigzagIndicatorData) => {
        if (!zigzagIndicatorData?.length || isNaN(zigzagIndicatorData[0]?.yPrice)) {
            return " ";
        }

        /*{ Move to the first data point without drawing a line
            Determine the starting point based on trend direction:
            - If the trend is upward (`upTrend === true`), start from the lowest point (`yLow`).
            - If the trend is downward (`upTrend === false`), start from the highest point (`yHigh`).
        }*/
        const pathData = [`M ${zigzagIndicatorData[0].xAxis} ${zigzagIndicatorData[0].upTrend ? zigzagIndicatorData[0].yLow : zigzagIndicatorData[0].yHigh}`];

         // Iterate over the data points starting from the second one and draw a line to each subsequent point based on trend direction
        const lineSegments = zigzagIndicatorData.slice(1).map(({ xAxis, yHigh, yLow, upTrend }) =>
            `L ${xAxis} ${upTrend ? yHigh : yLow}`
        );

        return pathData.concat(lineSegments).join(" ");
    };


    const pathData = prepareZigzag(zigzagIndicatorData);


    return (isVisible ?
        <div ref={chartRef}>
            <svg
                style={lineStyle}
                className="svg svg-barvisual-rslayer"
                id="zigzag-area"
            >
                <path
                    d={pathData}
                    data-disable-track-price="true"
                    onContextMenu={(e) => updateContextHit(e)}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseLeave={() => setHovered(false)}
                />
            </svg>

            {hovered && <div
                className="zigzag-tooltip"
                style={{
                    top: `${tooltipPosition.y}px`,
                    left: `${tooltipPosition.x}px`,
                    backgroundColor: colorInfo,
                    color: ThemeHelper.getFontColor(colorInfo),
                }}
            >
                Zigzag Indicator
            </div>}

        </div>
        :
        <></>
    );
};

const mapStateToProps = ({ RelatedInfoPanelReducers, DatagraphReducers }) => {
    const { aiSettings, zigzagIndicatorData } = RelatedInfoPanelReducers.RiPanelAI;
    const { majorPeriodicity } = DatagraphReducers.DataGraphReducer;
    const zzSettings = aiSettings?.zigzagIndicator;

    return { isVisible: zzSettings?.isVisible, zigzagIndicatorData, lineSettings: zzSettings?.lineStyle[majorPeriodicity] };
};

const mapDispatchToProps = (dispatch) => ({
    updateContextHit: (event) => {
        dispatch({ type: PriceChartConstants.ActionTypes.UPDATE_CONTEXT_HIT, contextMenuObj: { lineID: PriceChartConst.ZZ_INDICATOR } });
        dispatch({ type: PriceChartConstants.ActionTypes.SHOW_PRICE_CONTEXT_MENU, event, top: event.clientY })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZigzagVisual);