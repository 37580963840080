import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Overlay, Popover } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import ReactDOM from 'react-dom';

import { setMessage } from '../../Actions/AINewsActions';


const AISummaryModal = ({ showPopup, hidePopup, target, isReqActive, data, message, setMessage }) => {

    const popoverRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popoverElement = ReactDOM.findDOMNode(popoverRef.current);
            if (
                popoverElement &&
                !popoverElement.contains(event.target) &&
                showPopup
            ) {
                hidePopup();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        if (isReqActive) {
            timeoutId = setTimeout(() => {
                setMessage("It is taking time, Please wait ...");
            }, 10000);
        }

        return () => clearTimeout(timeoutId);

    }, [isReqActive]);

    return (
        <Overlay
            show={showPopup}
            target={target}
            style={{
                marginTop: '10px',
            }}
            placement="bottom"
            container={document.body}
            containerMargin={10}
        >
            <Popover
                id="popover-contained"
                ref={popoverRef}
                className="custom-popover"
                style={{
                    maxWidth: '500px',
                    maxHeight: '600px',
                    position: 'relative',
                    border: '1px solid rgb(1, 143, 238)',
                    padding: '0px',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {message ? <div>{message}</div> : <ReactMarkdown>{data}</ReactMarkdown>}
            </Popover>
        </Overlay>
    );
};

const mapStateToProps = ({ aiNewsReducer }) => {
    const { message, isReqActive } = aiNewsReducer;

    return { message, isReqActive };
};

const mapDispatchToProps = (dispatch) => ({
    setMessage: (msg) => dispatch(setMessage(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AISummaryModal);
