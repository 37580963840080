import { PrintMode } from '../../../../../../print/printmode';
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { showMoreSummary } from "../../../../../../Actions/SummaryBlockActions.js";
import { fetchCompetitiveEdgeData } from '../../../../../../Actions/AINewsActions.js';
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import AISummaryModal from '../../../../../../RayCustomControls/AIContent/AISummaryModal.jsx';

import UserInfoUtil from "UserInfoUtil";
import BaseServiceApi from 'BaseServiceApi';

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];


function Header() {
    const { headerData, symbolInfo } = useSelector((state) => state.summaryReducer)
    const { competitiveEdgeData, applicableInstruments } = useSelector((state) => state.aiNewsReducer)
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(false);
    const [target, setTarget] = useState(null);
    const textRef = useRef(null);
    const isCompetitiveEdgeEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.COMPETITIVE_EDGE_Entitlement)
    const isUSStock = applicableInstruments.includes(symbolInfo.SymTypeEnum) ? true : false;

    const handleClick = () => {
        if (!showPopup) {
            setTarget(textRef.current);
            dispatch(fetchCompetitiveEdgeData(symbolInfo.Symbol));
        }
        setShowPopup(!showPopup);
    };

    let needMoreBtn = true;
    if (headerData.summaryBlockDesc && headerData.summaryBlockDesc.longDescription === headerData.summaryBlockDesc.shortDescription) {
        needMoreBtn = false;
    }

    const renderPhone = () => {
        if (headerData.phone) {
            return <div className="sb-table-cell">{headerData.phone}</div>;
        }
        return "";
    }
    const renderUrl = () => {
        if (headerData.webUrl !== "http://null") {
            return <div className="sb-table-cell"><a className="link" href={headerData.webUrl} rel="noreferrer" target="_blank"> {headerData.webText}</a></div>
        }
        return "";
    }
    const renderCorporatePresentationItem = () => {
        if (headerData.corporatePresentationURL && !PrintMode.printing) {
            return (<div className="sb-table-cell cpCell">
                <a className="icn-link-cp" href={headerData.corporatePresentationURL} rel="noreferrer" target="_blank" title={headerData.corporatePresentationURL}></a>
            </div>);
        } else {
            return "";
        }
    }
    const renderXDivDate = () => {
        if (headerData.xDivDate) {
            return <div className="sb-table-cell xdivdate">{headerData.xDivDate}</div>;
        }
        return "";
    }
    const getheaderSummary = (headerData, showMore) => (headerData.summaryBlockDesc) ? (!showMore ? headerData.summaryBlockDesc.shortDescription : headerData.summaryBlockDesc.longDescription) : ""
    const showMoreStory = () => {
        story = getheaderSummary(headerData, !headerData.showMore)
        dispatch(showMoreSummary(!headerData.showMore));
    }
    let story = getheaderSummary(headerData, headerData.showMore)

    return (
        <div className="summary-block-catalog">
            <div className="summary-block-log">
                <div className="summary-block-log-hold">
                    <div className="sb-table">
                        <div className="sb-table-body">
                            <div className="sb-table-row">
                                {renderPhone()}
                                {renderUrl()}
                                <div className="sb-table-cell">{headerData.hqLocAndYrInc}</div>
                                <div className="sb-table-cell">{headerData.bankr}</div>
                                <div className="sb-table-cell">{headerData.mgmOwned}</div>
                            </div>
                        </div>
                    </div>
                    <div className="sb-table">
                        <div className="sb-table-body">
                            <div className="sb-table-row">
                                {headerData.epsRnk && headerData.epsRnk.length > 2 &&
                                    <div className="sb-table-cell">{headerData.epsRnk}</div>}
                                {renderCorporatePresentationItem()}
                                {renderXDivDate()}
                                <div className="sb-table-cell">{headerData.daysVolShort}</div>
                                <div className="sb-table-cell">{headerData.options}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="summary-block-record" style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                {story ? <p>{story}{needMoreBtn && (
                    !PrintMode.printing && (
                        <a className="more" onClick={showMoreStory}>
                            {!headerData.showMore
                                ? SummaryTransLateHelper.SUMMARY_BLOCK_SHOW_MORE
                                : SummaryTransLateHelper.SUMMARY_BLOCK_SHOW_LESS}
                        </a>
                    )
                )}

                    {!PrintMode.printing && isCompetitiveEdgeEntitled && isUSStock && (
                        <a ref={textRef} onClick={handleClick} className="more competitive-edge">
                            COMPETITIVE EDGE
                        </a>
                    )}

                    {showPopup &&
                        <AISummaryModal
                            showPopup={showPopup}
                            hidePopup={() => setShowPopup(false)}
                            data={competitiveEdgeData}
                            target={target}
                        />}

                </p> : ""}
            </div>
        </div>

    );

}

export default Header