import GetCompetitiveEdgeRequest from '../Requests/GetCompetitiveEdgeRequest';
import BaseServiceApi from 'BaseServiceApi'

class AINewsApi {

    getCompetitiveEdgeRequest(data) {
        const req = new GetCompetitiveEdgeRequest(data);
        return BaseServiceApi.processAIServiceCall(req)
    }
}

var aiNewsApi = new AINewsApi();
export default aiNewsApi;