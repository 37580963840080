import { AINewsConstants } from "../Constants/AINewsConstants";
import SymbolType from "SymbolType";
const { ActionTypes } = AINewsConstants;

const initialState = {
    isReqActive: false,
    message: "Loading ...",
    competitiveEdgeData: "",
    applicableInstruments: [SymbolType.USSTOCK, SymbolType.ADR, SymbolType.PREIPO, SymbolType.REIT]
}

const AINewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_MESSAGE:
            return {
                ...state,
                message: action.message
            }

        case ActionTypes.GET_COMPETITIVE_EDGE_REQUEST:
            return {
                ...state,
                competitiveEdgeData: "",
                message: "Loading ...",
                isReqActive: true
            }

        case ActionTypes.GET_COMPETITIVE_EDGE_SUCCESS:
        case ActionTypes.GET_COMPETITIVE_EDGE_FAILURE:
            return {
                ...state,
                competitiveEdgeData: action.data,
                message: action.message,
                isReqActive: false
            }

        default:
            return state;

    }
}


export default AINewsReducer;
